const body = document.body;
let prevScrollY = window.scrollY;

document.addEventListener('click', (event) => {
  if (event.target.closest('#back-to-top')) {
    event.preventDefault();
    window.scrollTo(0, 0);
  }
});

const checkScroll = () => {
  const newScrollY = window.scrollY;

  if (newScrollY > prevScrollY || newScrollY < window.screen.height / 2) {
    body.classList.remove('scrolling-up');
  } else {
    body.classList.add('scrolling-up');
  }

  prevScrollY = newScrollY;
};

export const scrollListener = () => {
  // Setup a timer
  let timeout;
  // Listen for scroll events
  window.addEventListener(
    'scroll',
    () => {
      // If there's a timer, cancel it
      if (timeout) {
        window.cancelAnimationFrame(timeout);
      }
      // Setup the new requestAnimationFrame()
      timeout = window.requestAnimationFrame(function () {
        // Run our scroll function
        checkScroll();
      });
    },
    false
  );
};
