import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const activeClass = 'scroll-link-is-active';

function initSection(section) {
  ScrollTrigger.create({
    trigger: section,
    start: 'top top',
    onToggle: self => {
      const link = document.querySelector(`.js-scroll-link[href="#${section.id}"]`);
      if (link && self.isActive) {
        const current = document.querySelector(`.${activeClass}`);
        if (current) {
          current.classList.remove(activeClass);  
        }
        link.classList.add(activeClass)
      }
    }
  });
}

function handleClick(link) {
	const active = document.querySelector(`.${activeClass}`);
	if (active) {
		active.classList.remove(activeClass);	
	}

	link.classList.add(activeClass);
}

export const sideNav = () => {
  const sections = document.querySelectorAll('.js-scroll-section');
  if (sections.length) {
    sections.forEach(section => initSection(section));
  }

	document.addEventListener('click', function(e) {
		const target = e.target;
		if (!target) {
			return;
		}

		const link = target.closest('.js-scroll-link');
		if (link) {
			handleClick(link);
		}
	});
}
