export const setupLazyImages = () => {
  const images = document.querySelectorAll('.js-lazy-image');

  if (images.length) {
    images.forEach((image) => {
      const loader = image.nextElementSibling;

      if (!image.complete) {
        loader.classList.remove('hidden');
        image.addEventListener('load', () => {
          loader.classList.add('hidden');
        });
      }
    });
  }
}
