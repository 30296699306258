import barba from '@barba/core';

export const initFilterBack = () => {
  document.addEventListener('click', (e) => {
    const backLink = e.target.closest('.js-filter-back');
    if (backLink) {
      e.preventDefault();
      e.stopPropagation();
  
      if (barba.history.previous) {
        history.back();
      } else {
        barba.go(backLink.href);
      }
    }
  });
}