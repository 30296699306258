const activeClass = 'subnav-link-is-active';

function handleClick(event) {
	const el = event.currentTarget;
	const active = document.querySelector(`.${activeClass}`);
	active.classList.remove(activeClass);
	el.classList.add(activeClass);
}

export const initPractice = () => {
	const els = document.querySelectorAll('.js-subnav-link');
	if (!els) {
		return;
	}

	els.forEach(el => el.addEventListener('click', handleClick));
}